import React from "react"
import { RouterProvider } from "react-router-dom"
import { Button, NextUIProvider } from "@nextui-org/react";
import { router } from "./routes/router";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import closeIcon from '@assets/images/icons/close.svg'

const App: React.FC = () => {
  return (
    <NextUIProvider locale="en-GB">
      <RouterProvider router={router} />

      <ToastContainer
        position="top-right"
        autoClose={2000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        theme="colored"
        className="custom-toastify"
        hideProgressBar
        closeButton={({ closeToast }) => (
          <Button color="default" variant="light" isIconOnly onClick={closeToast} >
            <img src={closeIcon} alt="close icon"  className="size-5"/>
          </Button>
        )}
      />
    </NextUIProvider>
  )
}

export default App
