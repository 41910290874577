import { URL_PARAMS } from "@/constants/constants";
import useDebounce from "@/hooks/useDebounce/useDebounce";
import { Button, Input, Listbox, ListboxItem, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import { Key, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WarningIcon from "@assets/images/icons/warning.svg"

interface IProps {
  items: IFile[];
  title: string;
  paramName?: string;
}

const SearchableDropdown = (props: IProps) => {
  const { items, title, paramName = URL_PARAMS.FILE_ID } = props

  const [files, setFiles] = useState<IFile[]>([])
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setFiles(items)
  }, [items])

  useEffect(() => {
    const newFiles = items.filter((item) => item.fileName.toLowerCase().includes(searchValue.toLowerCase()))
    setFiles(newFiles)
  }, [debouncedSearchValue]);

  const handleSelectFile = (id: Key) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(paramName, id as string);

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }

  return (
    <Popover placement="bottom-end" showArrow={true} classNames={{
      content: ["rounded-lg", "p-0"],
    }}>
      <PopoverTrigger>
        <div className="w-fit underline text-sm font-medium">{title}</div>
      </PopoverTrigger>
      <PopoverContent>
        <Input
          className="mb-2"
          variant="bordered"
          color="primary"
          radius="sm"
          size="lg"
          endContent={
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="size-4">
              <path fillRule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clipRule="evenodd" />
            </svg>
          }
          classNames={{
            inputWrapper: ["h-14"]
          }}
          onChange={handleInputChange}
        />
        <Listbox
          aria-label="Actions"
          onAction={(key) => handleSelectFile(key)}
          classNames={{
            base: ['max-h-[300px] overflow-x-auto']
          }}
        >
          {files.map((item) => (
            <ListboxItem key={item.id} showDivider>
              <div className="flex items-center gap-1">
                {item.isWarning && (
                  <div data-testid="warning-icon" className="size-6">
                    <img src={WarningIcon} alt="warning icon" />
                  </div>
                )}
                {item.fileName}
              </div>
            </ListboxItem>
          ))}
        </Listbox>
      </PopoverContent>
    </Popover>
  );
};

export default SearchableDropdown;