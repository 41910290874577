import DocViewer, { DocViewerRenderers, IDocument } from "@cyntler/react-doc-viewer";
import { useEffect, useRef, useState } from "react";
import Loading from "../Loading/Loading";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Tooltip } from "@nextui-org/react";
import FullScreenIcon from '@/assets/images/icons/fullscreen.svg';
import zoomOutIcon from '@/assets/images/icons/zoom-out.svg';
import zoomInIcon from '@/assets/images/icons/zoom-in.svg';

interface IProps {
  fileSelected: IFile;
  filesOuput: IDocument[];
}

export default function DocumentViewer(props: IProps) {
  const { fileSelected, filesOuput } = props;

  const [zoomLevel, setZoomLevel] = useState(1);
  const [viewerKey, setViewerKey] = useState(0);
  const viewerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Set a new key when the state changes to rerender the docviewer
    setViewerKey((prevKey) => prevKey + 1); 
  }, [fileSelected, zoomLevel, filesOuput]);

  const zoomOptions = [0.5, 0.75, 1, 1.25, 1.5, 2].map(value => ({
    key: value.toString(),
    label: `${value * 100}%`
  }));

  const activeDocument = {
    uri: fileSelected.fileUrl,
    fileType: fileSelected.fileType,
    fileName: fileSelected.fileName
  }

  const handleZoom = (delta: number) => {
    setZoomLevel(prevScale => {
      const newScale = Math.max(0.1, Math.min(prevScale + delta, 10));
      return newScale;
    });
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      viewerRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleZoomSelect = (key: string) => {
    setZoomLevel(parseFloat(key));
  };

  return (
    <div ref={viewerRef} className="border border-customGray-light rounded-lg relative w-full h-full flex flex-col overflow-hidden">
      <div className='border-b-1 border-customGray-light bg-white px-3 w-full flex items-center justify-between'>
        <Tooltip showArrow={true} placement={'bottom'} content={fileSelected?.fileName}>
          <span className="max-w-52 whitespace-nowrap overflow-hidden text-ellipsis">{fileSelected?.fileName}</span>
        </Tooltip>
        <div className='flex items-center gap-1'>
          <Button isIconOnly color="default" variant="light" aria-label="zoom-in" onClick={() => handleZoom(0.1)}>
            <img className="size-5" src={zoomInIcon} alt="zoom in icon" />
          </Button>
          <Dropdown classNames={{ content: ['min-w-[100px]'] }}>
            <DropdownTrigger>
              <Button size="sm" variant="bordered">
                {`${Math.round(zoomLevel * 100)}%`}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Zoom levels"
              onAction={(key) => handleZoomSelect(key as string)}
              selectedKeys={[zoomLevel.toString()]}
              selectionMode="single"
            >
              {zoomOptions.map((option) => (
                <DropdownItem key={option.key}>{option.label}</DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <Button isIconOnly color="default" variant="light" aria-label="zoom-out" onClick={() => handleZoom(-0.1)}>
            <img className="size-5" src={zoomOutIcon} alt="zoom out icon" />
          </Button>
        </div>

        <Button isIconOnly color="default" variant="light" aria-label="full-screen" onClick={toggleFullScreen}>
          <img className="size-5" src={FullScreenIcon} alt="full screen icon" />
        </Button>
      </div>
      <div className="flex-grow overflow-auto bg-white w-full h-full relative">
        <div className="h-full" style={{ transform: `scale(${zoomLevel})` }}>
          <DocViewer
            key={viewerKey}
            className="h-full"
            config={
              {
                header: {
                  disableFileName: true,
                  disableHeader: true
                },
                loadingRenderer: {
                  overrideComponent: Loading
                }
              }
            }
            initialActiveDocument={activeDocument}
            activeDocument={activeDocument}
            documents={filesOuput}
            pluginRenderers={DocViewerRenderers}
          />
        </div>
      </div>
    </div>
  );
}