import { Button } from "@nextui-org/react";
import DynamicForm from "../DynamicForm/DynamicForm"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
  inputTypeName: string;
  fields: IField[];
  getFormValues: Function;
  getHoveredIndex: Function;
  acceptedValue: IFileResult[];
  onProceed: Function
}
function DataExtractionWrapper(props: IProps) {
  const { fields, getFormValues, getHoveredIndex, acceptedValue, onProceed } = props;
  const [formValues, setFormValues] = useState<any>({})

  useEffect(() => {
    getFormValues(formValues)
  }, [formValues])

  const handleProceed = () => {
    onProceed()
  }

  const handleBlur = (formValues: {[key: string]: string}) => {
    setFormValues(formValues)
  }

  return (
    <div className="bg-customWhite-light h-full p-4 flex flex-col">
      <div className="flex items-center text-black text-base font-semibold mb-6 shrink-0">
        <div className="mr-1">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
          </svg>
        </div>
        <span>2: Data Extraction</span>
      </div>
      <div className="mb-6 text-customGray-dark text-base font-semibold shrink-0">Input type: {props.inputTypeName}</div>

      <DynamicForm fields={fields} onBlur={handleBlur} onHover={getHoveredIndex} isFocusFirstField={true} isResetForm={true} />

      <Button radius='sm' type="submit" color="primary" className='shrink-0 w-fit py-3 px-6 h-12' onClick={handleProceed}>
        Proceed
      </Button>
    </div>
  )
}

export default DataExtractionWrapper