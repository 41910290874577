import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import CustomPdf from '../CustomPdf/CustomPdf';
import { IPdfViewerProps } from '~@types/pdf-file-keywords';


export function PdfViewer(props: IPdfViewerProps) {
  const { fileKeywords, isLoading, defaultScale, renderError, highlightAreas, hoveredIndex } = props;

  return (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <div className='border-1 border-solid border-customGray-light rounded-lg h-full bg-white' style={{ flex: 1, overflow: "hidden" }}>
          {fileKeywords && <CustomPdf
            isLoading={isLoading}
            renderError={renderError}
            fileUrl={fileKeywords.fileUrl}
            fileName={fileKeywords.fileName}
            isSearchType={fileKeywords.isSearchType}
            isClosePdf={fileKeywords.isClosePdf}
            defaultScale={defaultScale}
            isShowThumbnail={false}
            highlightAreas={highlightAreas}
            hoveredIndex={hoveredIndex}
          />}
        </div>
      </Worker>
    </>
  );
}
