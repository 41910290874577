import iconLoading from '@assets/images/icons/icon-loading.gif'
interface IProps {
  title: string
}

export default function Loader(props: IProps) {
  const { title } = props
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="flex items-center bg-white py-2 px-3 rounded-lg">
        <img src={iconLoading} alt="loading" className="w-9 h-9 mr-2"/>
        <p data-testid="loader">{title}</p>
      </div>
    </div>
  )
}
