import Axios from 'axios';

const axios = Axios.create({
  baseURL: process.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

axios.interceptors.request.use(
  (config: any) => {
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (res: any) => {
    return res;
  },
  async (error: any) => {
    return Promise.reject(error);
  },
);

export default axios;