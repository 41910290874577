import { ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { RenderEnterFullScreenProps } from '@react-pdf-viewer/full-screen';
import { RenderGoToPageProps } from '@react-pdf-viewer/page-navigation';
import { SearchPlugin } from '@react-pdf-viewer/search';
import { RenderZoomInProps, RenderZoomOutProps } from '@react-pdf-viewer/zoom';
import { Button } from '@nextui-org/react';

import FullScreenIcon from '@/assets/images/icons/fullscreen.svg';
import zoomOutIcon from '@/assets/images/icons/zoom-out.svg';
import zoomInIcon from '@/assets/images/icons/zoom-in.svg';
import arrowUpIcon from '@/assets/images/icons/arrow-up.svg';
import arrowDownIcon from '@/assets/images/icons/arrow-down.svg';


type PdfToolbarProps = {
  Toolbar: (props: ToolbarProps) => React.ReactElement,
  fileUrl: string,
  searchPluginInstance: SearchPlugin,
  isBottom?: boolean,
  isClosePdf?: boolean,
  fileName?: string
  isSearchType?: boolean;
};

export function PdfToolbar(pdfToolbarProps: PdfToolbarProps) {
  const Toolbar = pdfToolbarProps.Toolbar;
  const isClosePdf = !!pdfToolbarProps.isClosePdf;
  const fileName = pdfToolbarProps.fileName;

  const renderEnterFullScreen = (toolbarSlot: ToolbarSlot) => {
    const { EnterFullScreen } = toolbarSlot;
    if (isClosePdf) {
      return (
        <EnterFullScreen>
          {(props: RenderEnterFullScreenProps) => (
            <Button isIconOnly color="default" variant="light" aria-label="full-screen" onClick={() => {
              window.opener = null;
              window.open("", "_self");
              window.close();
            }}>
              <img className='size-5' src={FullScreenIcon} alt="Full Screen Icon" />
            </Button>
          )}
        </EnterFullScreen>
      );
    } else {
      return (
        <EnterFullScreen>
          {(props: RenderEnterFullScreenProps) => (
            <Button isIconOnly color="default" variant="light" aria-label="full-screen" onClick={props.onClick}>
              <img className='size-5' src={FullScreenIcon} alt="Full Screen Icon" />
            </Button>
          )}
        </EnterFullScreen>
      );
    }
  };

  const renderToolbarTop = (toolbarSlot: ToolbarSlot, fileName?: string) => {
    const {
      Zoom,
      ZoomIn,
      ZoomOut,
    } = toolbarSlot;

    return (
      <div className="rpv-toolbar" role="toolbar" aria-orientation="horizontal" >
        <div className=''>
          {fileName}
        </div>

        <div className="flex items-center flex-grow justify-center">
          <div>
            {renderToolbarGoToPage(toolbarSlot, fileName)}
          </div>

          <div className='flex items-center'>
            <ZoomOut>
              {(props: RenderZoomOutProps) => (
                <Button isIconOnly color="default" variant="light" aria-label="zoom-out" onClick={props.onClick}>
                  <img className='size-5' src={zoomOutIcon} alt="Zoom Out Icon" />
                </Button>
              )}
            </ZoomOut>

            <Zoom />

            <ZoomIn>
              {(props: RenderZoomInProps) => (
                <Button isIconOnly color="default" variant="light" aria-label="zoom-in" onClick={props.onClick}>
                  <img className='size-5' src={zoomInIcon} alt="Zoom In Icon" />
                </Button>
              )}
            </ZoomIn>
          </div>
        </div>
        <div className="rpv-toolbar__right">
          {renderEnterFullScreen(toolbarSlot)}
        </div>
      </div>
    )
  };

  const renderToolbarGoToPage = (toolbarSlot: ToolbarSlot, fileName?: string) => {
    const {
      CurrentPageLabel,
      GoToNextPage,
      GoToPreviousPage,
      NumberOfPages,
    } = toolbarSlot;

    return (
      <div className="rpv-toolbar" role="toolbar" aria-orientation="horizontal" >
        <div className="rpv-toolbar__center">
          <div style={{ display: 'flex', justifyContent: 'center', flex: 'auto', alignItems: 'center' }}>
            <div className="rpv-core__display--hidden rpv-core__display--block-small">
              <div className="rpv-toolbar__item">
                <GoToPreviousPage>
                  {(props: RenderGoToPageProps) => (
                    <Button data-testid="button-previous" isDisabled={props.isDisabled} isIconOnly color="default" variant="light" aria-label="next" onClick={props.onClick}>
                      <img className='size-5' src={arrowUpIcon} alt="Arrow Up Icon" />
                    </Button>
                  )}
                </GoToPreviousPage>
              </div>
            </div>
            <div className="rpv-toolbar__item">
              <span className="rpv-toolbar__label">
                <span>Page</span> <span><CurrentPageLabel /></span> <span>/</span> <span><NumberOfPages /></span>
              </span>
            </div>
            <div className="rpv-core__display--hidden rpv-core__display--block-small">
              <div className="rpv-toolbar__item">
                <GoToNextPage>
                  {(props: RenderGoToPageProps) => (
                    <Button data-testid="button-next" isDisabled={props.isDisabled} isIconOnly color="default" variant="light" aria-label="previous" onClick={props.onClick}>
                      <img className='size-5' src={arrowDownIcon} alt="Arrow Down Icon" />
                    </Button>
                  )}
                </GoToNextPage>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Toolbar>
        {(toolbarSlot: ToolbarSlot) => {
          return (
            <>
              {(renderToolbarTop(toolbarSlot, fileName))}
            </>
          );
        }}
      </Toolbar>
    </>
  );
}
