import usePreventBackButton from "@/hooks/usePreventBackButton/usePreventBackButton";
import React from "react"

interface IProps {
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
}

function ExtractLayout({ leftComponent,  rightComponent}: IProps) {
  usePreventBackButton();

  return (
    <div className="flex w-full h-full">
      <div className="h-full w-3/6 flex-1 border-solid border-r-1 border-customWhite-medium  relative before:absolute before:top-0 before:left-0 before:h-full before:w-full before:bg-cover before:bg-center before:bg-no-repeat before:bg-custom-bg before:z-[1]" data-testid="left-section">
        <div className="relative z-[2] h-full">{leftComponent}</div>
      </div>

      <div className="h-full w-3/6 flex-1" data-testid="right-section">
        <div className="h-full flex flex-col">{rightComponent}</div>
      </div>
    </div>
  )
}

export default ExtractLayout
