import { useEffect, useState } from "react"
import UploadButton from "../UploadButton/UploadButton"

import { Card, CardBody, Button } from "@nextui-org/react";
import { formatFileSize } from "@/constants/common"
import { LIMIT_FILE_SIZE, VALID_TYPES } from "@/constants/constants";

interface IProps { 
  onSelectedFilesChange: Function
}

function UploadFiles(props: IProps) {
  const { onSelectedFilesChange } = props
  const [selectedFiles, setSelectedFiles] = useState<IFileSelected[]>([]);

  useEffect(() => {
    onSelectedFilesChange(selectedFiles)
  }, [selectedFiles])

  const handleSelectedFiles = (files: IFileSelected[]) => {
    setSelectedFiles(files)
  }

  const handleRemoveFile = (index: number) => {
    const updatedFiles = selectedFiles.filter((_, itemIdx) => index !== itemIdx);
    setSelectedFiles(updatedFiles)
  }

  return (
    <div
      className="flex flex-col h-full p-4"
      data-testid="upload-section"
    >
      <div className="flex items-center text-black text-base font-semibold mb-6 shrink-0">
        <div className="mr-1">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
          </svg>
        </div>
        <span>1: File Upload(s)</span>
      </div>
      <div className="shrink-0">
        <UploadButton limitSize={LIMIT_FILE_SIZE} validTypes={VALID_TYPES} onSelectedFiles={handleSelectedFiles} selectedFiles={selectedFiles}></UploadButton>
      </div>

      <div className="text-black text-base font-normal mb-3 mt-4 shrink-0">
      Total Files: {selectedFiles.length}
      { !!selectedFiles.filter(fileObj => !fileObj.isValid).length && 
        <span className="text-error ml-6">Failed: {selectedFiles.filter(fileObj => !fileObj.isValid).length}</span>
      } 
      </div>

      <div className="files-uploaded overflow-y-auto scrollbar-custom pr-1 flex-grow max-h-fit">
        {selectedFiles.map((fileObj, index: number) => {
          const uniqueKey = fileObj.file.name + '-' + index;
          return (
            <Card key={uniqueKey} className="px-4 py-2 mb-2 mt-1 shadow-md rounded-md">
              <CardBody className="overflow-visible flex-row items-center gap-6 p-0">
                <div className="w-6 h-6">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`size-6 ${!fileObj.isValid && 'text-red-500'}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                  </svg>
                </div>

                <div className="text-black/80 text-sm font-normal">
                  <div className={`${!fileObj.isValid && 'text-error'}`} data-testid="file-info">
                      {fileObj.file.name}
                      <span className={`mb-2 ml-4 text-black/60 text-sm ${!fileObj.isValid && 'text-error'}`}>
                        {!fileObj.isValid ? 'Failed. File Error' : formatFileSize(fileObj.file.size)}
                      </span>
                    </div>
                </div>
                <Button isIconOnly color="danger" aria-label="Like" className="bg-transparent border-0 text-black/50 ml-auto h-6" onClick={() => handleRemoveFile(index)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
                    <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
                  </svg>
                </Button>
              </CardBody>
            </Card>
          )
        })}
      </div>
    </div>
  )
}

export default UploadFiles
