const main_key = 'document_processing'

// local storage
export const setLocalItem = (key: string, value: any) => {
  if (!window.localStorage) {
    throw new Error('localStorage global not available')
  }

  window.localStorage.setItem(`${main_key}_${key}`, JSON.stringify(value))
}

export const getLocalItem = (key: string): any => {
  if (!window.localStorage) {
    throw new Error('localStorage global not available')
  }

  let result: any
  const dataString = window.localStorage.getItem(`${main_key}_${key}`)
  if (typeof dataString === 'string') result = JSON.parse(dataString)

  return result
}

export const removeItem = (key: string) => {
  if (!window.localStorage) {
    throw new Error('localStorage global not available')
  }

  window.localStorage.removeItem(`${main_key}_${key}`)
}