import { Button, Card, CardBody, Tab, Tabs } from "@nextui-org/react";
import { PdfViewer } from "../PdfViewer/PdfViewer";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import ImageViewer from "../ImageViewer/ImageViewer";
import { IHighLightAreas } from "@/types/pdf-file-keywords";
import { useSearchParams } from "react-router-dom";
import { FILE_TYPES, URL_PARAMS } from "@/constants/constants";
import { fetchImage } from "@/constants/common";
import DocumentViewer from "../DocumentViewer/DocumentViewer";
import WarningIcon from "@assets/images/icons/warning.svg"     

interface IProps {
  onFilesSelectedChange: Function;
  files: IFile[];
  onRemoveFile?: Function;
  hideFileViewer?: boolean;
  headerElement: ReactElement;
  hoveredIndex?: number;
  hideContent?: boolean;
  hideCloseIcon?: boolean;
  paramName?: string;
  hasOutput?: boolean;
  onDownloadOuputFile?: Function
}

export default function ListFile(props: IProps) {
  const { 
    onFilesSelectedChange, 
    hideFileViewer, 
    headerElement, 
    files, 
    onRemoveFile, 
    hoveredIndex, 
    hideContent, 
    hideCloseIcon, 
    paramName = URL_PARAMS.FILE_ID, 
    hasOutput, 
    onDownloadOuputFile } = props
  const [fileSelected, setFileSelected] = useState<IFile>()
  const [image, setImage] = useState<File | null>(null);

  const [searchParams] = useSearchParams();
  const fileIdParam = searchParams.get(paramName) || '';

  const filesOutput = useMemo(() => {
    return files.map((item) => ({
      uri: item.fileUrl,
      fileName: item.fileName,
      fileType: item.fileType
    }))
  }, [files])

  useEffect(() => {
    if (fileIdParam && fileSelected && fileIdParam !== fileSelected.id) {
      handleSelectionChange(fileIdParam)
    }
  }, [fileIdParam])

  useEffect(() => {
    const loadImageSelected = async () => {
      if (fileSelected) {
        const imageFetched = await fetchImage(fileSelected);
        setImage(imageFetched);
      } else {
        setImage(null);
      }
    };

    loadImageSelected();
  }, [fileSelected]);

  const handleClose = (id: string) => {
    if (onRemoveFile) {
      onRemoveFile(id);
    }
  };

  const getHighLightAreas = useCallback((): IHighLightAreas[] => {
    return files.find((item) => item.id === fileSelected?.id)?.highlightAreas || [];
  }, [files]);

  const handleSelectionChange = (id: string | number) => {
    const fileMatched = files.find((item) => item.id === id)
    setFileSelected(fileMatched)
    onFilesSelectedChange(fileMatched?.id || '', hasOutput)
  }

  const CustomTabTitle = ({ file, onClose }: { file: IFile; onClose: (id: string) => void }) => {
    return (
      <div className="flex items-center space-x-2">
        {file.isWarning && (
          <div data-testid="warning-icon" className="size-6">
            <img src={WarningIcon} alt="warning icon" />
          </div>
        )}
        <span>{file.fileName}</span>

        {!hideCloseIcon && (
          <Button
            isIconOnly
            variant="light"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClose(file.id);
            }}
            className="ml-2  w-4 h-4 min-w-4  focus:outline-none"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="size-4">
              <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
            </svg>
          </Button>
        )}
      </div>
    );
  };

  const docViewerComponent = useMemo(() => {
    if (!fileSelected) return null;

    return (
      <DocumentViewer fileSelected={fileSelected} filesOuput={filesOutput} />
    );
  }, [fileSelected, filesOutput]);

  const handleDownload = () => {
    if (onDownloadOuputFile) onDownloadOuputFile()
  }

  return (
    <div className={`${!hideContent ? "h-[calc(100vh-theme('height.header'))]" : 'h-fit'} flex flex-col ${hideFileViewer ? 'h-auto pt-4 px-4' : 'p-4'} `}>
      {headerElement}

      <div className="flex gap-2 flex-grow max-w-full overflow-x-auto scrollbar-custom">
        <div className="flex w-full flex-col">
          <Tabs
            aria-label="Options"
            variant="underlined"
            color="primary"
            selectedKey={fileSelected?.id || ''}
            onSelectionChange={handleSelectionChange}
            classNames={{
              panel: ["h-[calc(100%-2.5rem)]", 'overflow-y-hidden'],
              tabList: ['h-10', 'overflow-x-auto', 'overflow-y-hidden', 'scrollbar-custom'],
              tab: ['mb-2', 'px-2'],
              cursor: ['w-full']
            }}
          >
            {files?.map((file) => (
              <Tab key={file.id} title={<CustomTabTitle file={file} onClose={handleClose} />}>
                {!hideContent && (
                  <Card className={`shadow-none h-full bg-transparent`}>
                    <CardBody className="h-full px-0">
                      {!hideFileViewer && !hasOutput && FILE_TYPES.PDF.includes(fileSelected?.fileType || '') && (
                        <div className="h-full flex-grow overflow-y-auto" data-testid="pdf-container">
                          <PdfViewer fileKeywords={{ fileUrl: fileSelected?.fileUrl || '', keywords: [''], fileName: fileSelected?.fileName }} highlightAreas={getHighLightAreas()} hoveredIndex={hoveredIndex} />
                        </div>
                      )}

                      {!hideFileViewer && !hasOutput && FILE_TYPES.IMAGE.includes(fileSelected?.fileType || '') && (
                        <div data-testid="image-container" className="w-full h-full">
                          <ImageViewer file={image} highlightAreas={getHighLightAreas()} hoveredIndex={hoveredIndex} />
                        </div>
                      )}

                      {!!hasOutput && docViewerComponent}
                    </CardBody>
                  </Card>
                )}
              </Tab>
            ))}
          </Tabs>
          {!!hasOutput && (
            <Button
              startContent={
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
              }
              color="primary"
              size="lg"
              radius="sm"
              className="w-fit"
              onClick={() => handleDownload()}
            >Download All</Button>
          )}
        </div>
      </div>
    </div>
  )
}
