import axios from "./base.api"

const getInputTypes = async (query: string): Promise<IInputTypes[]> => {
  const response = await axios.get(`/input-types${!!query ? `?query=${query}` : ''}`)
  return response.data
}

const uploadFiles = async (formData: FormData): Promise<{ extraction_id: string }> => {
  const response = await axios.post('/extraction', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data
};

const getExtractionResult = async (id: string): Promise<IExtractionResult> => {
  const response = await axios.get(`/extraction/${id}`);
  return response.data
};

const removeFileById = async (extractionId: string, fileId: string): Promise<{ message: string }> => {
  const response = await axios.delete(`/extraction/${extractionId}/file/${fileId}`);
  return response.data
};

const updateExtractionResult = async (extractionId: string, data: IUpdateResultRequest): Promise<{ message: string }> => {
  const response = await axios.put(`/extraction/${extractionId}`, data);
  return response.data
};

const renameWorkflow = async (extractionId: string, description: string): Promise<{ message: string }> => {
  const response = await axios.put(`/extraction/${extractionId}/description`, { description });
  return response.data
};

const uploadNewFiles = async (extractionId: string, formData: FormData): Promise<IExtractionResult> => {
  const response = await axios.post(`/extraction/${extractionId}/file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data
};

const downloadOutputFile = async (extractionId: string): Promise<{ download_url: string }> => {
  const response = await axios.get(`/extraction/${extractionId}/download-url`);
  return response.data;
};

export { getInputTypes, uploadFiles, getExtractionResult, removeFileById, updateExtractionResult, renameWorkflow, uploadNewFiles, downloadOutputFile }