import { highlightPlugin, RenderHighlightsProps } from '@react-pdf-viewer/highlight';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { IHighLightAreas } from '@/types/pdf-file-keywords';

const HighlightPluginInstance = (highlightAreas: IHighLightAreas[], hoveredIndex: number | undefined) => {
  const isAllValuesNull = (obj: IHighLightAreas) => Object.values(obj).every(value => !value);

  const renderHighlights = (props: RenderHighlightsProps) => {
    return (
      <div>
        {highlightAreas.map((area, idx) => {
          if (isAllValuesNull(area)) return null;

          const isHovered = hoveredIndex === idx;
          const style = Object.assign(
            {},
            isHovered ? { background: 'rgba(255, 255, 0, 0.3)' }: { border: '1px solid red' },
            props.getCssProperties(area, props.rotation)
          );

          return <div key={`highlight-${props.pageIndex}-${idx}`} className="highlight-area" style={style} />;
        })}
      </div>
    );
  };

  return highlightPlugin({
    renderHighlights
  });
};

export default HighlightPluginInstance;
