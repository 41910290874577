import React, { useRef, useEffect, useState } from 'react';
import { ExtractionResult } from '../../types';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';
import { IHighLightAreas } from '@/types/pdf-file-keywords';

interface ImageViewerProps {
  file: File | null;
  extractedData?: ExtractionResult[];
  hoveredIndex?: number | null;
  highlightAreas: IHighLightAreas[]
}

const ImageViewer: React.FC<ImageViewerProps> = ({ file, extractedData, hoveredIndex, highlightAreas }) => {
  const scaleDefault = 1
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(scaleDefault);
  const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        setImage(img);
      };
    }
  }, [file]);

  useEffect(() => {
    if (image) {
      drawImage();
    }
  }, [image, extractedData, hoveredIndex, scale, panOffset, isFullscreen]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const drawImage = () => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (!canvas || !container || !image) return;
  
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
  
    const dpr = window.devicePixelRatio || 1;
    canvas.width = container.clientWidth * dpr;
    canvas.height = container.clientHeight * dpr;
    canvas.style.width = `${container.clientWidth}px`;
    canvas.style.height = `${container.clientHeight}px`;
  
    ctx.scale(dpr, dpr);
  
    const containerAspectRatio = container.clientWidth / container.clientHeight;
    const imageAspectRatio = image.width / image.height;
  
    let drawWidth, drawHeight;
    if (containerAspectRatio > imageAspectRatio) {
      drawHeight = container.clientHeight;
      drawWidth = drawHeight * imageAspectRatio;
    } else {
      drawWidth = container.clientWidth;
      drawHeight = drawWidth / imageAspectRatio;
    }
  
    const scaledWidth = drawWidth * scale;
    const scaledHeight = drawHeight * scale;
  
    const offsetX = (container.clientWidth - scaledWidth) / 2 + panOffset.x;
    const offsetY = (container.clientHeight - scaledHeight) / 2 + panOffset.y;
  
    ctx.clearRect(0, 0, container.clientWidth, container.clientHeight);
    
    ctx.imageSmoothingEnabled = scale <= 1;
    ctx.imageSmoothingQuality = 'high';
  
    ctx.drawImage(image, offsetX, offsetY, scaledWidth, scaledHeight);
  
    highlightAreas?.forEach((item, index) => {
      const { left, top, width, height } = item;
      ctx.strokeStyle = index === hoveredIndex ? 'yellow' : 'red';
      ctx.lineWidth = 1 / scale; 
      ctx.strokeRect(
        offsetX + left * scaledWidth,
        offsetY + top * scaledHeight,
        width * scaledWidth,
        height * scaledHeight
      );
  
      if (index === hoveredIndex) {
        ctx.fillStyle = 'rgba(255, 255, 0, 0.3)';
        ctx.fillRect(
          offsetX + left * scaledWidth,
          offsetY + top * scaledHeight,
          width * scaledWidth,
          height * scaledHeight
        );
      }
    });
  };

  const handleZoom = (delta: number) => {
    setScale(prevScale => {
      const newScale = Math.max(0.1, Math.min(prevScale + delta, 10));
      return newScale;
    });
  };

  const resetView = () => {
    setScale(scaleDefault);
    setPanOffset({ x: 0, y: 0 });
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setTimeout(() => {
      resetView()
    }, 200);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    setIsDragging(true);
    setDragStart({ x: e.clientX - panOffset.x, y: e.clientY - panOffset.y });
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (isDragging) {
      const dx = e.clientX - dragStart.x;
      const dy = e.clientY - dragStart.y;
      setPanOffset({ x: dx, y: dy });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const zoomOptions = [0.5, 0.75, 1, 1.25, 1.5, 2].map(value => ({
    key: value.toString(),
    label: `${value * 100}%`
  }));

  const handleZoomSelect = (key: string) => {
    setScale(parseFloat(key));
  };

  return (
    <div
      ref={containerRef}
      className='border-1 border-customGray-light rounded-lg relative overflow-hidden w-full h-full'
    >
      <canvas
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
        className='bg-white'
        data-testid="image-viewer-canvas"
      />
      <div
        className='border-b-1 border-customGray-light bg-white absolute top-0 left-0 px-3 w-full flex items-center justify-between'
      >
        <span className='max-w-52 whitespace-nowrap overflow-hidden text-ellipsis'>{file?.name}</span>
        <div className='flex items-center gap-1'>
          <Button isIconOnly color="default" variant="light" onClick={() => handleZoom(0.1)} style={{ marginRight: '5px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6" />
            </svg>
          </Button>
          <Dropdown classNames={{ content: ['min-w-[100px]'] }}>
            <DropdownTrigger>
              <Button size="sm" variant="bordered">
                {`${Math.round(scale * 100)}%`}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Zoom levels"
              onAction={(key) => handleZoomSelect(key as string)}
              selectedKeys={[scale.toString()]}
              selectionMode="single"

            >
              {zoomOptions.map((option) => (
                <DropdownItem key={option.key}>{option.label}</DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <Button isIconOnly color="default" variant="light" onClick={() => handleZoom(-0.1)} style={{ marginRight: '5px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM13.5 10.5h-6" />
            </svg>
          </Button>
        </div>
        <Button isIconOnly color="default" variant="light" onClick={toggleFullscreen}>
          {isFullscreen ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 9V4.5M9 9H4.5M9 9 3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5 5.25 5.25" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
            </svg>
          )}
        </Button>
      </div>
    </div>
  );
}

export default ImageViewer;