import { useEffect, useRef, useState } from 'react';
import { Button } from '@nextui-org/react';
import { formatFileSize } from '@/constants/common';

interface IProps {
  limitSize: number;
  validTypes: string[];
  onSelectedFiles: Function;
  selectedFiles: IFileSelected[]
}

const UploadButton = (props: IProps) => {
  const { limitSize, validTypes, onSelectedFiles, selectedFiles } = props;
  const [files, setFiles] = useState<IFileSelected[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onSelectedFiles(files)
  }, [files])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const listFile: File[] = Array.from(event.target.files || []);
    processFiles(listFile);
  };

  const processFiles = (listFile: File[]) => {
    const classifyFiles = listFile.map(file => ({
      file,
      isValid: file.size <= limitSize && validTypes.includes(file.type),
      formatSize: formatFileSize(file.size),
      invalidSize: file.size > limitSize,
      invalidType: !validTypes.includes(file.type),
    }));

    setFiles([...selectedFiles, ...classifyFiles]);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const droppedFiles: File[] = Array.from(event.dataTransfer.files);
    processFiles(droppedFiles);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleUploadClick = () => {
    fileInputRef?.current?.click();
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <input
        type="file"
        id="fileInput"
        className="hidden"
        data-testid="file-input"
        multiple
        onChange={handleFileChange}
        ref={fileInputRef}
        accept=".pdf, .png, .jpg, .jpeg"
      />

      <div
        className={`w-full h-52 border-dashed border-1 rounded-lg transition-all duration-300 ease-in-out ${
          isDragging
            ? 'border-blue-400 bg-secondary/[.08]'
            : 'border-gray-400 bg-white hover:border-blue-400 hover:bg-secondary/[.08]'
        } flex flex-col items-center justify-center cursor-pointer`}
        onClick={handleUploadClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        data-testid="drop-zone"
        onKeyDown={() => {}}
      >
        {isDragging ? (
          <p className="text-blue-600">Drop your files here</p>
        ) : (
          <>
            <Button color='primary' className="bg-transparent w-full h-full p-0 inline-block" onClick={handleUploadClick} >
              <div className='text-black flex justify-center'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m0-3-3-3m0 0-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75" />
                </svg>
              </div>
              <div className='mt-4 text-black/80 text-base font-normal'><span className='text-primary underline'>Upload</span> or drop files here</div>
              <div className='text-center text-black/60 text-sm mt-2'>
                <div>The maximum size per file is 10 MB</div>
                <div>Supported file types: pdf, png, jpg</div>
              </div>
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadButton;
