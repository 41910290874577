
import { ReactElement, useEffect, useMemo, useState } from "react"
import { Button, Input, Listbox, ListboxItem } from "@nextui-org/react";
import useDebounce from "@/hooks/useDebounce/useDebounce";

interface IProps {
  onProceed: Function;
  isDisableTypes: boolean;
  onSelectedTypeChange: Function;
  types: IInputTypes[];
  onFilterItems: Function;
}

function SelectFileTypes(props: IProps) {
  const { onProceed, isDisableTypes, onSelectedTypeChange, types, onFilterItems } = props
  const [selectedKeys, setSelectedKeys] = useState<Set<string | number>>(new Set(null));
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  useEffect(() => {
    onFilterItems(debouncedSearchValue);
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (types.length > 0) {
      setSelectedKeys(new Set(['']));
    }
  }, [types]);

  const selectedValue = useMemo(
    () => Array.from(selectedKeys),
    [selectedKeys]
  );

  useEffect(() => {
    onSelectedTypeChange(selectedValue);
  }, [selectedKeys]);

  const ListboxWrapper = ({ children }: { children: React.ReactNode }) => (
    <div className="w-full rounded-small border-default-200 dark:border-default-100 flex-grow overflow-y-auto scrollbar-custom py-1 max-h-fit">
      {children}
    </div>
  );

  const handleSelectionChange = (keys: 'all' | Set<string | number>) => {
    if (keys instanceof Set) {
      setSelectedKeys(keys);
    }
  };

  const handleExtractData = () => {
    onProceed(selectedValue);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleClickItem = (item: IInputTypes) => {
    const newSelectedKeys = new Set(selectedKeys);
    newSelectedKeys.delete(item.id);
    setSelectedKeys(newSelectedKeys);
  };

  return (
    <div className="bg-customWhite-light h-full p-4">
      <div className="flex flex-col gap-2 w-4/5 h-full">
        <div className="flex items-center text-black text-base font-semibold mb-1 shrink-0">
          <div className="mr-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
            </svg>
          </div>
          <span>2: Data Extraction</span>
        </div>

        <div className="shrink-0">
          <Input
            isClearable
            type="text"
            label="Input Type Selection"
            labelPlacement={'outside-left'}
            placeholder="Search"
            size="md"
            className="w-full mb-6 gap-8"
            variant="underlined"
            onClear={() => onFilterItems('')}
            onChange={handleValueChange}
            startContent={
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
              </svg>
            }
            classNames={{
              inputWrapper: ["w-full"],
              mainWrapper: ["w-full"],
              label: ["whitespace-nowrap", "p-0"]
            }}
          />
        </div>
        <ListboxWrapper>
          <Listbox
            aria-label="single selection"
            variant="light"
            disallowEmptySelection
            selectionMode="single"
            selectedKeys={selectedKeys}
            onSelectionChange={handleSelectionChange}
            classNames={{
              list: ["gap-3"],
              base: ["p-0"]
            }}
            className="pr-1"
            disabledKeys={isDisableTypes ? types.map((item) => item.id) : []}
          >
            {types?.map((item) => (
              <ListboxItem
                textValue={item.id}
                key={item.id}
                className={`
                border-small
              border-customGray-light
              bg-white
                rounded
                py-3
                px-4
                h-10
                ${selectedKeys.has(item.id) ? 'bg-customPurple-light border-customPurple-medium' : ''}
              data-hover:bg-customPurple-light
              data-hover:border-customPurple-medium
              `}
                classNames={{ selectedIcon: ["hidden"] }}
                hideSelectedIcon={true}
                onClick={() => handleClickItem(item)}
              >
                <span className="text-black font-medium text-sm">{item.name}</span>
              </ListboxItem>
            ))}
          </Listbox>
        </ListboxWrapper>

        <Button isDisabled={isDisableTypes} radius="sm" color="primary" variant="solid" className="h-11 w-fit mt-6 shrink-0"
          startContent={
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
            </svg>
          }
          onClick={handleExtractData}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
}

export default SelectFileTypes;