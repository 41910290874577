import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@nextui-org/react";
import { ReactElement, useEffect } from "react";

interface IProps {
  isOpenModal: boolean;
  title: string;
  content: string | ReactElement;
  onOpenModalChange: Function;
  onConfirm: Function
}

function ConfirmModal(props: IProps) {
  const { isOpenModal, title, content, onConfirm, onOpenModalChange } = props
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  useEffect(() => {
    if (isOpenModal) onOpen() 
  }, [isOpenModal])

  useEffect(() => {
    onOpenModalChange(isOpen)
  }, [isOpen])

  const confirm = () => {
    onConfirm()
    onClose()
  }
  
  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange} isDismissable={false}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
              <ModalBody>
                {content}
                <div className="border-b-1 border-black40 w-full px-6"></div>
              </ModalBody>
              <ModalFooter>
                <Button variant="solid" radius="sm" className="bg-customGray-medium text-white h-12 py-3 px-6" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" radius="sm" className="h-12 py-3 px-6" onPress={confirm}>
                  Proceed
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
    </Modal>
  )
}

export default ConfirmModal