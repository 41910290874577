import ExtractLayout from "@/layouts/ExtractLayout/ExtractLayout"
import ListFile from "../ListFile/ListFile"
import DataExtractionWrapper from "../DataExtractionWrapper/DataExtractionWrapper"
import FirstStepHeader from "../FirstStepHeader/FirstStepHeader"
import { useCallback, useEffect, useRef, useState } from "react"
import { showError, showSuccess, showWarn } from "@/helpers/toastify"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { getExtractionResult, removeFileById, updateExtractionResult, uploadNewFiles } from "@/apis/extract.api"
import ConfirmModal from "../ConfirmModal/ConfirmModal"
import { PATH } from "@/constants/paths"
import * as _ from 'lodash';
import { formatFileSize, handleError, prepareDataHighlight, showWarningFile } from "@/constants/common"
import { toast } from "react-toastify"
import { LIMIT_FILE_SIZE, URL_PARAMS, VALID_TYPES } from "@/constants/constants"
import Loader from "../Loader/Loader"
import { setLocalItem } from "@/helpers/storage"
import { COOKIES } from "@/constants/cookies"

function DataExtraction() {
  const navigate = useNavigate()
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const extractionId = searchParams.get(URL_PARAMS.EXTRACTION_ID) || '';
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [extractData, setExtractData] = useState<IExtractionResult>({ extraction_id: '', files: [], description: '' })
  const [acceptedValue, setAcceptedValue] = useState<IFileResult[]>([])
  const [fields, setFields] = useState<IField[]>([])
  const [fileId, setFileId] = useState<string>('')
  const [files, setFiles] = useState<IFile[]>([])
  const [hoveredIndex, setHoveredIndex] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [additionalFiles, setAdditionalFiles] = useState<IFileSelected[]>([]);
  const [inputTypeName, setInputTypeName] = useState<string>('')

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await getExtractionResult(extractionId);
        setExtractData(data);
        setLocalItem(COOKIES.WORKFLOW_NAME, data.description)
      } catch (error) {
        showError(handleError(error));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData()

    return () => {
      toast.dismiss();
    };
  }, [])

  useEffect(() => {
    if (!additionalFiles.length) return;

    const errorMessages: string[] = [];
    for (const item of additionalFiles) {
      if (!item.isValid) {
        if (item.invalidSize) {
          errorMessages.push(`Max size of ${formatFileSize(LIMIT_FILE_SIZE)} MB per file exceeded.`);
        }
        if (item.invalidType) {
          errorMessages.push('Invalid document type.');
        }
      }
    }

    if (errorMessages.length) {
      const errorElement = (
        <>
          {errorMessages.map((msg, index) => <div key={index}>{msg}</div>)}
        </>
      );
      showError(errorElement);
      return;
    }

    handleUploadNewFiles();
  }, [additionalFiles]);

  useEffect(() => {
    setAcceptedValue(_.cloneDeep(extractData.files))
  }, [extractData])

  useEffect(() => {
    getData()
  }, [acceptedValue])

  const getData = useCallback(() => {
    const data = prepareDataHighlight(acceptedValue)
    setFiles(data)
  }, [acceptedValue])

  const handleFilesSelected = (id: string) => {
    toast.dismiss()
    setFileId(id)
    setFields([])
    updateParams(id)
    showWarningFile(id, acceptedValue, 'extracted_value')
    getData()

    setTimeout(() => { generateFieldsAndInputTypeName(id) }, 0)
  }

  const generateFieldsAndInputTypeName = (id?: string) => {
    const idx = acceptedValue.findIndex((file) => file.id === id)
    if (idx === -1) return

    const formFields = acceptedValue[idx].extraction_result.map((item) => {
      return {
        type: 'text',
        label: item.field_name,
        value: item.extracted_value,
        name: item.field_alias,
        percentage: item.confidence_score ? item.confidence_score * 100 : item.confidence_score
      }
    })
    setInputTypeName(acceptedValue[idx].input_type_name ?? '')
    setFields([...formFields])
  }

  const handleFormValue = (formValues: { [key: string]: string }) => {
    const file = acceptedValue.find((file) => file.id === fileId);
    if (!file) return;

    const extractionResult = file.extraction_result;
    const updatedResult = extractionResult.map((item) => {
      const fieldAlias = item.field_alias;
      if (formValues.hasOwnProperty(fieldAlias)) {
        return { ...item, extracted_value: formValues[fieldAlias] };
      }
      return item;
    });

    file.extraction_result = updatedResult;
    setAcceptedValue([...acceptedValue]);
  };

  const handleRemoveFile = async (id: string) => {
    const filesFiltered = acceptedValue.filter((x) => x.id !== id)
    setAcceptedValue(filesFiltered)

    try {
      const data = await removeFileById(extractionId, id)
      showSuccess(data.message)
    } catch (error) {
      showError(handleError(error))
    }

    if (!filesFiltered.length) {
      navigate(`${PATH.DEFAULT}`, { replace: true });
    }
  }

  const updateParams = (id: string) => {
    const params = new URLSearchParams(location.search);
    if (extractionId) params.set(URL_PARAMS.EXTRACTION_ID, extractionId);
    if (id) params.set(URL_PARAMS.FILE_ID, id);
    navigate(`${location.pathname}?${params.toString()}`);
  }

  const updateExtraction = async () => {
    const files: IFileRequest[] = acceptedValue.map(({ id, extraction_result }) => ({
      id,
      extraction_result: extraction_result.map(({ field_alias, extracted_value }) => ({
        field_alias,
        user_accepted_value: extracted_value ?? ''
      }))
    }));

    try {
      await updateExtractionResult(extractionId, { files })
      const params = new URLSearchParams({ extractionId });

      navigate(`${PATH.DEFAULT}/${PATH.OUTPUT_PREVIEW}?${params.toString()}`, { replace: true })
    } catch (error) {
      showError(handleError(error))
    }
  }

  const handleProceed = () => {
    const isWarning = acceptedValue.some((item) => item.extraction_result.some((x) => !x.extracted_value))
    if (isWarning) {
      setIsOpenModal((prev) => !prev)
    } else {
      updateExtraction()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const listFile: File[] = Array.from(event.target.files || []);
    const classifyFiles: IFileSelected[] = listFile.map(file => ({
      file,
      isValid: file.size <= LIMIT_FILE_SIZE && VALID_TYPES.includes(file.type),
      formatSize: formatFileSize(file.size),
      invalidSize: file.size > LIMIT_FILE_SIZE,
      invalidType: !VALID_TYPES.includes(file.type),
    }));

    setAdditionalFiles(classifyFiles);
  };

  const handleUploadNewFiles = async () => {
    setIsLoading(true)
    const formData = new FormData();
    Array.from(additionalFiles).forEach((fileObj) => {
      formData.append('file', fileObj.file);
    });

    try {
      const data = await uploadNewFiles(extractionId, formData)
      const updatedAcceptedData = [...acceptedValue, ...data.files]
      setAcceptedValue(updatedAcceptedData)
    } catch (error) {
      showError(handleError(error))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && <Loader title="Preparing Data Extraction in Progress …" />}
      <ExtractLayout
        leftComponent={
          <div>
            <ListFile
              onFilesSelectedChange={handleFilesSelected}
              files={files}
              onRemoveFile={handleRemoveFile}
              hoveredIndex={hoveredIndex}
              headerElement={
                <FirstStepHeader
                  files={files}
                  isAddButtonVisible={true}
                  onUploadNewFiles={() => fileInputRef?.current?.click()}
                />
              }
            />
          </div>
        }
        rightComponent={
          <DataExtractionWrapper
            inputTypeName={inputTypeName}
            acceptedValue={acceptedValue}
            fields={fields}
            getFormValues={handleFormValue}
            getHoveredIndex={setHoveredIndex}
            onProceed={handleProceed}
          />
        }
      />

      <ConfirmModal
        isOpenModal={isOpenModal}
        onOpenModalChange={setIsOpenModal}
        onConfirm={updateExtraction}
        title="Confirmation"
        content={
          <p>File(s) with missing data won't be included in the output. Proceed? This action is irreversible.</p>
        }
      />

      <input
        type="file"
        id="fileInput"
        className="hidden"
        data-testid="file-input"
        multiple
        onChange={handleFileChange}
        ref={fileInputRef}
        accept=".pdf, .png, .jpg, .jpeg"
      />
    </>
  )
}

export default DataExtraction
