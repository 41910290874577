import FilterFiles from "../FilterFiles/FilterFiles"
import TableCustom from "../TableCustom/TableCustom"
import { useCallback, useEffect, useRef, useState } from "react"
import RenameWorkflow from "../RenameWorkflow/RenameWorkflow"
import { ALL_FILES_MODE, CHANNEL, STAGE, STATUS, URL_PARAMS } from "@/constants/constants"
import { Link, useSearchParams } from "react-router-dom"
import { renameWorkflow } from "@/apis/extract.api"
import { showError, showSuccess } from "@/helpers/toastify"
import { formatDateTime, handleError } from "@/constants/common"
import { exportAllFile, exportWorkflow, getAutocomplete, getAutocompleteAllFile, getFiles, getQuantity, getWorkflows } from "@/apis/all-files.api"
import FileSaver from "file-saver"

// icons
import ComputerIcon from "@assets/images/icons/computer.svg"
import SFTPIcon from "@assets/images/icons/sftp.svg"
import EmailIcon from "@assets/images/icons/email.svg"
import PPTIcon from "@assets/images/icons/ppt-file.svg"
import { Divider, Tooltip } from "@nextui-org/react"
import _ from "lodash"
import React from "react"

const WORKFLOW_COLUMNS = [
  {
    name: "Channel",
    uid: "channel",
    sortable: true,
  },
  {
    name: "Workflow ID",
    uid: "extraction_id",
    sortable: true,
  },
  {
    name: "Workflow Name",
    uid: "description",
    sortable: true,
  },
  {
    name: "Status",
    uid: "status",
  },
  {
    name: "Organization",
    uid: "organization",
    sortable: true,
  },
  {
    name: "Input Files",
    uid: "total_input_file",
  },
  {
    name: "Output Files",
    uid: "total_output_file",
  },
  {
    name: "Total Transactions",
    uid: "total_transaction",
    sortable: true,
  },
  {
    name: "Created",
    uid: "created_at",
    sortable: true,
  },
  {
    name: "Created by",
    uid: "created_by",
  },
  {
    name: "Last Modified",
    uid: "updated_at",
    sortable: true,
  },
  {
    name: "Modified by",
    uid: "updated_by",
  },
]

const FILE_COLUMNS = [
  {
    name: "Channel",
    uid: "channel",
    sortable: true,
  },
  {
    name: "File Name",
    uid: "file_name",
    sortable: true,
  },
  {
    name: "I/O",
    uid: "process_type",
    sortable: true,
  },
  {
    name: "File Size",
    uid: "file_size",
    sortable: true,
  },
  {
    name: "Workflow ID",
    uid: "extraction_id",
  },
  {
    name: "Organization",
    uid: "organization",
    sortable: true,
  },
  {
    name: "Transactions",
    uid: "transactions",
    sortable: true,
  },
  {
    name: "Extracted",
    uid: "extracted",
  },
  {
    name: "Updated",
    uid: "updated_at",
    sortable: true,
  },
  {
    name: "Updated by",
    uid: "updated_by",
    sortable: true,
  },
]

const defaultData = {
  data: [],
  items_per_page: 50,
  page: 1,
  total_items: 0
}

function AllFilesWrapper() {
  const [searchParams] = useSearchParams()
  const mode = searchParams.get(URL_PARAMS.MODE);

  const [isLoading, setIsLoading] = useState(false)
  const [autocompleteData, setAutocompleteData] = useState<IAutocompleteData[]>([])
  const [currentColumns, setCurrentColumns] = useState(WORKFLOW_COLUMNS)
  const [currentData, setCurrentData] = useState<ITableData>(defaultData)
  const [rowsSelected, setRowsSelected] = useState<string[]>([])
  const [resetSelection, setResetSelection] = useState(false)
  const [quantity, setQuantity] = useState<IQuantity>()
  const [modelFilters, setModelFilters] = useState<IModelFilters>({
    keyword: "",
    end_date: null,
    items_per_page: 50,
    page: 1,
    sort_field: "",
    sort_direction: "",
    start_date: null,
    attribute: "",
  })

  const prevModelFiltersRef = useRef(modelFilters)

  useEffect(() => {
    if (!_.isEqual(modelFilters, prevModelFiltersRef.current)) {
      fetchData(mode)
      prevModelFiltersRef.current = modelFilters
    }
  }, [modelFilters])

  useEffect(() => {
    if (!mode) return 

    fetchData(mode)
    fetchAutocomplete()

    if (mode === ALL_FILES_MODE.FILES) {
      getTotalQuantity()
    }
  }, [mode])

  const handleRenameWorkflow = async (value: string, itemId: string) => {
    try {
      const data = await renameWorkflow(itemId, value);
      showSuccess(data.message);
      fetchData(mode ?? ALL_FILES_MODE.WORKFLOWS);
    } catch (error) {
      showError(handleError(error));
    }
  };

  const fetchData = async (modeParam = mode) => {
    const effectiveMode = modeParam ?? ALL_FILES_MODE.WORKFLOWS;
    if (!effectiveMode) return;
    setIsLoading(true);

    try {
      const isWorkflowMode = effectiveMode === ALL_FILES_MODE.WORKFLOWS;
      const data = isWorkflowMode ?
        await getWorkflows(modelFilters) :
        await getFiles(modelFilters);
      setCurrentColumns(isWorkflowMode ? WORKFLOW_COLUMNS : FILE_COLUMNS);
      setCurrentData(data);
    } catch (error) {
      showError(handleError(error));
      setCurrentData(defaultData);
    } finally {
      setIsLoading(false);
    }
  };

  const getChannelIcon = (channel: string) => {
    const icons = {
      [CHANNEL.PLATFORM]: ComputerIcon,
      [CHANNEL.SFTP]: SFTPIcon,
      [CHANNEL.EMAIL]: EmailIcon,
    }
    return icons[channel as keyof typeof icons]
  }

  const customRenderCell = useCallback(
    (data: IAllFilesResponse | IWorkflowResponse, columnKey: string) => {
      const cellValue = data[columnKey as keyof typeof data]

      switch (columnKey) {
        case "channel":
          const icon = getChannelIcon(cellValue as string)
          return (
            icon && (
              <div className="flex justify-center">
                <img src={icon} alt={`${cellValue} icon`} />
              </div>
            )
          )
        case "file_name":
          return (
            <div className="flex whitespace-nowrap gap-1">
              <img src={PPTIcon} alt="ppt file icon" />
              <span>{cellValue}</span>
            </div>
          )
        case "extraction_id":
          const current_state = data["current_state"] as StateKeys
          const url = `${STAGE[current_state]}?${URL_PARAMS.EXTRACTION_ID}=${data["extraction_id"]}`
          return (
            <div className="flex whitespace-nowrap">
              <Link
                className="underline cursor-pointer text-primary"
                to={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="max-w-[200px] truncate">{cellValue}</div>
              </Link>
            </div>
          )
        case "status":
          return <div className="whitespace-nowrap">{STATUS[cellValue as keyof typeof STATUS]}</div>
        case "created_at":
        case "updated_at":
          return <div className="whitespace-nowrap">{formatDateTime(cellValue)}</div>
        case "total_input_file":
        case "total_output_file":
          return <div className="whitespace-nowrap">{cellValue} File(s)</div>
        case "description":
          return (
            <RenameWorkflow
              defaultValue={cellValue}
              itemId={data.extraction_id}
              onValueChange={handleRenameWorkflow}
            />
          )
        default:
          return <div className="whitespace-nowrap">{cellValue}</div>
      }
    },
    [],
  )

  const handleAutocompleteChange = (value: IAutocompleteData) => {
    updateModelFilters({
      keyword: value.name,
      attribute: value.type,
    })
  }

  const handleDateRangeChange = ({
    startDate,
    endDate,
  }: {
    startDate: string | null
    endDate: string | null
  }) => {
    updateModelFilters({
      start_date: startDate,
      end_date: endDate,
    })
  }

  const handlePageChange = ({
    page,
    rowsPerPage,
    sort,
    direction,
  }: {
    page: number
    rowsPerPage: number
    sort: string | number
    direction: string
  }) => {
    updateModelFilters({
      page,
      items_per_page: rowsPerPage === 0 ? null : rowsPerPage,
      sort_field: sort,
      sort_direction: direction,
    })
  }

  const updateModelFilters = (updatedFields: Partial<typeof modelFilters>) => {
    setModelFilters(prev => ({
      ...prev,
      ...updatedFields,
    }))
  }

  const handleExport = async () => {
    if (rowsSelected.length <= 0) return;

    try {
      const response = mode === ALL_FILES_MODE.WORKFLOWS
        ? await exportWorkflow(rowsSelected)
        : await exportAllFile(
          (currentData.data as IAllFilesResponse[])
            .reduce((acc: any, item) => {
              if (rowsSelected.includes(item.id)) {
                item.process_type === 'Input' ? acc.input_ids.push(item.id) : acc.output_ids.push(item.id);
              }
              return acc;
            }, { input_ids: [], output_ids: [] })
        );

      if (response) {
        FileSaver(response.data, response.fileName);
        setRowsSelected([]);
        setResetSelection(prev => !prev);
      }
    } catch (error) {
      showError(handleError(error));
    }
  };

  const fetchAutocomplete = async (value: string = '') => {
    try {
      const isWorkflowMode = mode === ALL_FILES_MODE.WORKFLOWS
      const data = isWorkflowMode ? await getAutocomplete(value) : await getAutocompleteAllFile(value)
      setAutocompleteData(data)
    } catch { }
  }

  const getTotalQuantity = async () => {
    try {
      const data = await getQuantity()
      setQuantity(data)
    } catch { }
  }

  const summaryData = [
    { count: 0, label: "All Transactions", tooltip: "0 All Transactions" },
    { count: quantity?.total_input_file ?? 0, label: "Files Processed", tooltip: `${quantity?.total_input_file ?? 0} Files Processed` },
    { count: quantity?.total_output_file ?? 0, label: "Output Files", tooltip: `${quantity?.total_output_file ?? 0} Output Files` },
  ];

  return (
    <div className="w-screen h-full flex flex-col px-11 py-8 relative before:absolute before:top-0 before:left-0 before:h-full before:w-full before:bg-cover before:bg-center before:bg-no-repeat before:bg-workflows-bg before:z-[1]">
      <FilterFiles
        rowsSelected={rowsSelected}
        autocompleteData={autocompleteData}
        onAutocompleteChange={handleAutocompleteChange}
        onDateRangeChange={handleDateRangeChange}
        onSearchChange={fetchAutocomplete}
        onExport={handleExport}
        summaryComp={
          mode === ALL_FILES_MODE.FILES ? (
            <div className="flex items-center border-1 rounded bg-[#E8E8E8] bg-opacity-60 py-3 px-6 gap-5 h-12 ml-auto whitespace-nowrap max-w-[700px] min-w-[200px]">
              {summaryData.map((item, index) => (
                <React.Fragment key={index}>
                  <Tooltip showArrow={true} content={item.tooltip}>
                    <div className="text-sm items-center gap-1 text-[#6B6B6B] flex truncate">
                      <div className="text-lg font-bold truncate">
                        {item.count} <span className="text-sm font-normal">{item.label}</span>
                      </div>
                    </div>
                  </Tooltip>

                  {index < summaryData.length - 1 && <Divider orientation="vertical" />}
                </React.Fragment>
              ))}
            </div>
          ) : (
            <></>
          )
        }
      />

      <div className="h-[calc(100%-3rem-1.5rem)]">
        <TableCustom
          data={currentData.data}
          columns={currentColumns}
          renderCustomCell={customRenderCell}
          onPageChange={handlePageChange}
          isLoading={isLoading}
          onSelectRowChange={setRowsSelected}
          mode={mode}
          total={currentData.total_items}
          resetSelection={resetSelection}
          entityId={mode === ALL_FILES_MODE.WORKFLOWS ? 'extraction_id' : 'id'}
        />
      </div>
    </div>
  )
}

export default AllFilesWrapper
