import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Chip,
  DateRangePicker,
  RangeValue,
} from "@nextui-org/react"
import { ReactElement, useEffect, useRef, useState } from "react"
import { CalendarDate } from "@internationalized/date"

import FileExportIcon from "@assets/images/icons/file-export.svg"
import closeIcon from '@assets/images/icons/close.svg'
import { formatToDateString } from "@/constants/common"
import useDebounce from "@/hooks/useDebounce/useDebounce"

interface IProps {
  summaryComp?: ReactElement
  onAutocompleteChange: (data: { name: string; type: string }) => void
  onDateRangeChange: (dateRange: { startDate: string | null, endDate: string | null }) => void
  autocompleteData: IAutocompleteData[]
  onExport: () => void
  rowsSelected: string[]
  onSearchChange: (value: string) => void
}

function FilterFiles(props: IProps) {
  const { summaryComp, onAutocompleteChange, onDateRangeChange, autocompleteData, onExport, rowsSelected, onSearchChange } = props

  const [searchValue, setSearchValue] = useState<string | number | null>("")
  const [dateRange, setDateRange] = useState<RangeValue<CalendarDate> | null>()
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 300);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!searchValue) {
      onAutocompleteChange({ name: debouncedInputValue, type: "" })
    }
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      onSearchChange(debouncedInputValue)
    }
  }, [debouncedInputValue])

  useEffect(() => {
    onDateRangeChange({
      startDate: dateRange?.start ? formatToDateString(dateRange.start) : null,
      endDate: dateRange?.end ? formatToDateString(dateRange.end) : null,
    })
  }, [dateRange])

  const handleSelectionChange = (selectedValue: string | number | null) => {
    setSearchValue(selectedValue)
  }

  useEffect(() => {
    if (!searchValue) return

    const selectedItem = autocompleteData[Number(searchValue)]
    if (selectedItem) {
      onAutocompleteChange(selectedItem)
    }
  }, [debouncedInputValue, searchValue])

  return (
    <div className="relative flex gap-2 z-10">
      <Autocomplete
        allowsCustomValue
        label="Search"
        variant="bordered"
        className="max-w-xs min-w-60"
        size="sm"
        color="default"
        selectedKey={searchValue}
        onSelectionChange={handleSelectionChange}
        onInputChange={setInputValue}
        inputProps={{
          classNames: {
            inputWrapper: "bg-white",
          },
        }}
        popoverProps={{
          className: "min-w-[500px]",
        }}
        listboxProps={{
          emptyContent: "No result found for your search.",
        }}
      >
        {autocompleteData.map((item, index) => (
          <AutocompleteItem
            key={index}
            value={item.name}
            endContent={
              <Chip radius="sm" className="bg-customGray-light text-white text-xs">
                {item.type}
              </Chip>
            }
          >
            {item.name}
          </AutocompleteItem>
        ))}
      </Autocomplete>

      <DateRangePicker
        data-testid="date-range-picker"
        label="Date Range"
        className="max-w-xs"
        variant="bordered"
        size="sm"
        startContent={
          dateRange && (
            <Button
              className="order-3 -mr-2"
              variant="light"
              onClick={() => setDateRange(null)}
              isIconOnly
              radius="full"
              size="sm"
            >
              <img src={closeIcon} alt="close icon"  className="size-4"/>
            </Button>
          )
        }
        value={dateRange}
        onChange={setDateRange}
        classNames={{
          selectorButton: ["order-4"],
        }}
      />

      {!!summaryComp && summaryComp}

      <Button
        className="text-white ml-auto"
        color="primary"
        size="lg"
        radius="sm"
        isDisabled={!rowsSelected.length}
        onClick={() => onExport()}
        startContent={<img src={FileExportIcon} alt="export icon" />}
      >
        Export
      </Button>
    </div>
  )
}

export default FilterFiles
