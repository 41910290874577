import { ReactElement } from "react";
import { FILE_TYPES } from "./constants";
import axios from "axios";
import { showWarn } from "@/helpers/toastify";
import { ToastPosition } from "react-toastify";
import { CalendarDate, toCalendarDateTime } from "@internationalized/date";

export function formatFileSize(bytes: number) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Bytes";

  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = bytes / Math.pow(1024, i);

  return size.toFixed(2) + " " + sizes[i];
}

export const prepareDataHighlight = (data: IFileResult[], key: keyof IExtractionItem = 'extracted_value') => {
  return data.map((item) => {
    const isPDF = FILE_TYPES.PDF.includes(item.file_type);

    return {
      id: item.id,
      fileName: item.original_file_name,
      fileUrl: item.s3_presigned_url,
      isWarning: item.extraction_result.some((x) => !x[key]),
      highlightAreas: item.extraction_result.map((x) => {
        const boundingBox = x.bounding_box || {};
        const scale = isPDF ? 100 : 1;

        return {
          height: boundingBox.height ? boundingBox.height * scale : null,
          left: boundingBox.left ? boundingBox.left * scale : null,
          top: boundingBox.top ? boundingBox.top * scale : null,
          width: boundingBox.width ? boundingBox.width * scale : null,
          pageIndex: isPDF && x.page ? x.page - 1 : x.page,
        };
      }),
      fileType: item.file_type
    };
  });
};

export const fetchImage = async (fileSelected: IFile | undefined): Promise<File | null> => {
  if (fileSelected && FILE_TYPES.IMAGE.includes(fileSelected.fileType)) {
    try {
      const response = await fetch(fileSelected.fileUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      return new File([blob], fileSelected.fileName, { type: blob.type });
    } catch (error) {
      console.error('Error fetching the image:', error);
      return null;
    }
  }
  return null;
};

export const getCurrentFormattedDate = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[now.getMonth()];
  const year = now.getFullYear();

  return `${hours}${minutes}${seconds} ${day} ${month} ${year}`;
}

export const formatToDateString = (date?: CalendarDate) => {
  const today = new Date();
  const calendarDate = date || new CalendarDate(today.getFullYear(), today.getMonth() + 1, today.getDate());

  const currentDateTime = toCalendarDateTime(calendarDate);
  return currentDateTime.toString()
};

export const handleError = (error: any, message?: string | ReactElement) => {
  const defaultMessage = 'Something went wrong'
  if (message) {
    return message;
  }

  if (axios.isAxiosError(error)) {
    const axiosMessage = error.response?.data?.detail || error.message;
    return axiosMessage || defaultMessage;
  }

  return error?.message || defaultMessage;
};

const renderItems = (items: {field_name: string; [key: string]: string | null}[]) => {
  const filteredItems = items.filter((item) => item.field_name?.trim());

  return filteredItems.map((item, index) => (
    <span key={`${item.field_name}-${index}`}>
      {item.field_name}
      {index < filteredItems.length - 1 && ", "}
    </span>
  ));
};

export const showWarningFile = (
  id: string,
  acceptedValue: IFileResult[],
  valueKey: 'user_accepted_value' | 'extracted_value',
  toastOptions?: { position?: ToastPosition, closeOnClick: boolean, closeButton: boolean }
) => {
  const selectedFile = acceptedValue.find((item) => item.id === id);
  if (selectedFile) {
    const emptyFieldsData = selectedFile.extraction_result
      .filter(item => !item[valueKey])
      .map(({ field_name, [valueKey]: value }) => ({ field_name, [valueKey]: value }));

    if (emptyFieldsData.length > 0) {
      showWarn(
        (
          <>
          <p>{selectedFile.original_file_name} has {emptyFieldsData.length} empty field(s): {renderItems(emptyFieldsData)}. </p>
          <p>This file won't be included in the output.</p>
        </>
        ),
        toastOptions?.position,
        toastOptions?.closeOnClick,
        toastOptions?.closeButton
      );
    }
  }
};

export function formatDateTime(utcDateString: string) {
  const utcDateStringWithoutMicroseconds = utcDateString.slice(0, 23) + 'Z';
  const utcDate = new Date(utcDateStringWithoutMicroseconds);
  const day = String(utcDate.getDate()).padStart(2, '0');
  const month = String(utcDate.getMonth() + 1).padStart(2, '0');
  const year = utcDate.getFullYear();
  const hours = utcDate.getHours();
  const minutes = String(utcDate.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const hours12 = hours % 12 || 12;
  const formattedDate = `${day}/${month}/${year} ${hours12}:${minutes} ${ampm}`;
  
  return formattedDate
}