import { ReactElement } from "react";
import { toast, ToastPosition } from "react-toastify";

export const showWarn = (content: string | ReactElement, position?: ToastPosition, closeOnClick: boolean = true, closeButton: boolean = true) => {
  return (
    toast.warn(
      <>
        {content}
      </>, {
      autoClose: false,
      closeButton,
      position,
      closeOnClick
    })
  )
}

export const showSuccess = (content: string | ReactElement, position?: ToastPosition) => {
  return (
    toast.success(
      <>
        {content}
      </>, {
      position
    }
    )
  )
}

export const showError = (content: string | ReactElement, position?: ToastPosition) => {
  return (
    toast.error(
      <>
        {content}
      </>, {
      autoClose: 5000,
      position
    }
    )
  )
}